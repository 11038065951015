import { SelectOptionType } from '@octano/global-ui';
import { DefaultValues } from 'react-hook-form';
import { format as formatRut } from 'rut.js';
import {
  DataOwnSustainer,
  SustainerStepResponse,
} from '../../../../types/sustainerTypes';
import { getSelectedOption } from '../../../../utils/selectFormat';
import {
  maritalStatusOptions,
  relationshipOptions,
  RELATIONSHIP_OWN_SUSTAINER,
} from '../../../../utils/selectOptions';
import { FieldsSustainerForm } from './SustainerForm';

export const formatDefaultValues = (
  data: SustainerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): DefaultValues<FieldsSustainerForm> => {
  const { sustainer } = data;
  const selectedCommune = getSelectedOption(
    sustainer?.communeId,
    communeOptions,
  );

  let ownSustainer: boolean | undefined;
  if (data.sustainerPassportAvailable) {
    ownSustainer = data.sustainer?.rut === data.student.rut ? true : undefined;
  } else {
    ownSustainer = undefined;
  }

  let hasPassport: boolean | undefined = false;
  if (data.sustainerPassportAvailable) {
    hasPassport = Boolean(
      sustainer?.passportNumber && sustainer.passportNumber !== '',
    );
  }
  return {
    hasPassport,
    relationship: relationshipOptions.find(
      (row) => row.value === sustainer?.relationshipId,
    ),
    occupation: sustainer?.occupation,
    names: sustainer?.names,
    paternalLastName: sustainer?.paternalLastName,
    maternalLastName: sustainer?.maternalLastName,
    rut: sustainer?.rut ? formatRut(sustainer.rut) : '',
    passportCountry: getSelectedOption(
      sustainer?.passportCountry,
      countryOptions,
    ),
    passportNumber: sustainer?.passportNumber,
    addressStreet: sustainer?.addressStreet,
    addressNumber: sustainer?.addressNumber,
    addressExtra: sustainer?.addressExtra,
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: sustainer?.phone,
    cellphone: sustainer?.cellPhone,
    email: sustainer?.email,
    ownSustainer,
    maritalStatus: getSelectedOption(
      sustainer?.maritalStatusId,
      maritalStatusOptions,
    ),
    nationality: getSelectedOption(
      sustainer?.nationality.id,
      nationalityOptions,
    ),
  };
};

export const formatDataOwnSustainer = (
  data: SustainerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): DataOwnSustainer => {
  const { student } = data;
  const selectedCommune = getSelectedOption(student?.communeId, communeOptions);
  return {
    relationship: relationshipOptions.find(
      (row) => row.value === RELATIONSHIP_OWN_SUSTAINER,
    ),
    names: student.names,
    paternalLastName: student.paternalLastName,
    maternalLastName: student.maternalLastName,
    rut: student.rut ? formatRut(student.rut) : undefined,
    passportCountry: getSelectedOption(
      student?.passportCountry,
      countryOptions,
    ),
    passportNumber: student.passportNumber,
    addressStreet: student.addressStreet,
    addressNumber: student.addressNumber,
    addressExtra: student.addressExtra,
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: student.phone,
    cellphone: student.cellPhone,
    email: student.email,
    maritalStatus: getSelectedOption(
      student.maritalStatusId,
      maritalStatusOptions,
    ),
    nationality: getSelectedOption(
      student?.nationality?.id,
      nationalityOptions,
    ),
  };
};
